// Import Bootstrap variables before customizing them
@import "~bootstrap/scss/functions";
@import "./variables";

$primary:#98be19;
$secondary:#7ba51f;



// Import Bootstrap styles after customizing variables
@import "~bootstrap/scss/bootstrap";

:root {
  --bs-btn-color: $primary; // Custom button text color
  --bs-btn-bg: #007bff; // Custom button background color
  --bs-btn-border-color: #007bff; // Custom button border color
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body, html, #root {
  height: 100%;
  margin: 0;
  width: 100%;
}



/*coordinate*/
.center-coordinate-button {
  position: relative;
  top: 35px;
  margin-left: 1em;
}

.center-coordinate-button-read {
  top: 27px;
  position: relative;
  margin-left: 1em;
}
.small-col {
  width: 10%;
  max-width: 9%;
}
.container-visualize-delete {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .small-col {
    width: 60%;
    max-width: 60%;
  }
}

.-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

@media (max-width: 750px) and ( min-height: 400px) {
  .-row {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
  }

  .container-visualize-delete {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: row;

    .small-col {
      width: 20%;
      max-width: 20%;

      .center-coordinate-button-read {
        margin: 0;
      }
    }
  }
  .container-submit.small-col {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;

    .center-coordinate-button {
      width: 70%;
      margin: 2em 0 0 0;
      position: relative;
      top: 0;
    }

  }
}

/*forecast*/
.container-btn-forecast {
  display: flex;
  justify-content: center;
  //flex-direction: row;

}
.btn-margin-forecast {
  margin: 2em;

}
.forecast-margin-alert {
  margin: 2em;

}
.forecast-title-container {
  display: flex;
  justify-content: space-between;
}

/* payment*/
.btn-stripe {
  margin-top: 1em;
  width: 100%;
}


/* page Premium */
.premium-stripe-container {
  display: flex;
  justify-content: center;


  .premium-stripe {
    width: 100%;
    margin: 1em 6em 1em 0;
  }
}

._404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .info {
    width: 300px;
    h1 {
      font-size: 70px;
    }
  }
}

._500 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .info {
    width: 300px;
    h1 {
      font-size: 70px;
    }
  }
  .message {
    width : 700px;
  }
}

/* Style for the menu container */
.menu-container {
  position: relative;
}

/* Style for the menu button */
.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 10;
}

/* Style for the bars in the menu button */
.bar {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.3s;
}

/* Change the bars when menu is open */
.bar.change:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.bar.change:nth-child(2) {
  opacity: 0;
}

.bar.change:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Style for the menu */
.menu {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

/* Show the menu when it is open */
.menu.open {
  display: block;
}

/* Style for the menu items */
.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  border-bottom: 1px solid #ddd;
}

.menu ul li a {
  display: block;
  padding: 15px;
  color: #333;
  text-decoration: none;
  text-align: center;
}

.menu ul li a:hover {
  background-color: #f4f4f4;
}

a {
  cursor: pointer;
}

.container-app {
  display: flex;
  justify-content: center;
  min-height: calc(100% - 300px);
  align-items: center;
  background-color: $primary;
  width: 100%;
  flex-direction: column;




  .page {
    width: 1000px;
    height: 100%;
    margin: 1em;
    background-color: white;
    padding: 5em;
    border-radius: 1em;
  }

}

.container-app-login {
  display: flex;
  min-height: calc(100%);
  background-color: $primary;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;

  .page {
    width: 1000px;
    height: 100%;
    margin: 1em;
    background-color: white;
    padding: 5em;
    border-radius: 1em;
  }
  .page.large {
    width: 100vw;
  }

  .left, .right {
    width: 40%;

    .message-login {
      margin-top:4em;
    }

    .logo-left {
      width: 300px;
      height: 100px;
    }
  }
}




.footer {
  height: 300px;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .links {
    width: 1000px;
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    .title {
      color: white;
    }
    .links-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .links {
        width: 300px;
        //padding: 1em;

        ul {
          list-style-type: none; /* Removes bullet points from the list */
          padding: 0; /* Removes default padding if needed */
          margin: 0;
          li {
            a {
              color:$primary;
              text-decoration: none;
            }
          }
        }
      }


    }
  }
  .container-copyright {
    display: flex;
    justify-content: center;
    width: 1000px;
    margin-top:5em;
    .copyright {
      width: 300px;
      font-size: 10px;
      color: white;
    }
  }

}

.collapse.navbar-collapse {
  z-index: 1000;
}

@media (max-width: 750px) and (min-height: 400px) {
  .collapse:not(.show) {
    display: block;
    position: fixed;
    background-color: $primary;
    border-radius: 1em;
    padding: 1em;
    margin-left: 1em;
    top: 7em;
    right: 1em;

  }
  .container-app-login {
    .page {
      width: 92%;
      padding: 1em;
    }
    .page.large {
      width: 92%;
      padding: 1em;
    }

  }
  .choose-on-map {
    margin-top:4em;
  }
  .footer {
    height: 380px;
    .links {
      width: calc(100% - 1em);
      justify-content: center;

      .links-container {
        flex-direction: column;
        justify-content: center;
        .links {
          width: 300px;
        }
      }
    }
    .container-copyright {
      width: 100%;
    }
  }

  .container-btn-forecast {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    .btn-forecast {
      width : 110px;
      font-size: 15px;
    }
    .btn-margin-forecast {
      margin: 1em 1em 1em 1em;
    }
  }
}


@media only screen and (min-device-width: 480px)
and (max-device-width: 940px) and (orientation: landscape) {
  .navbar {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .navbar-nav {
    flex-direction: row;
  }
  .nav-link {
    margin: 0 0 0 15px;
  }

  .container-app-login {
    .page {
      width: 92%;
      padding: 1em;
      height: calc(100vh - 100px)
    }
    .page.coordinate {
      min-height: 1280px;
    }
    .page.subscribe {
      min-height: 540px;
    }
    .page.subscribe.b2b {
      min-height: 600px;
    }
    .page.contact {
      min-height: 570px;
    }
    .page.left {
      width: 40%
    }

    .page.right {
      width: 40%;

      .message-login {
        display: none;
      }
    }


    .page.large {
      width: calc( 100vw);
      div {
        h1 {
          font-size: 12px;
        }
      }
    }


  }
  .choose-on-map {
    margin-top:4em;
  }
  .footer {
    height: 100px;
    flex-direction: row;
    .links {
      width: 100px;
      flex-direction: row;
      justify-content: center;

      .links-container {
        flex-direction: row;
        justify-content: center;
        .links {
          width: 200px;
        }
      }
    }
    .container-copyright {
      width: 100%;
    }
  }

  .container-btn-forecast {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    .btn-forecast {
      width :  100px;
      font-size: 12px;
      margin: 1em 1em 1em 1em;
    }
  }
}

@media only screen and (min-device-width: 480px)
and (max-device-width: 670px) and (orientation: landscape) {
  .navbar {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .navbar-nav {
    flex-direction: row;
  }
  .nav-link {
    margin: 0 0 0 15px;
  }
  .container-app-login {
    .page {
      width: 92%;
      padding: 1em;
      height: calc(100vh - 100px)
    }
    .page.left {
      width: 40%;
      .logo-left {
        width: 235px;
        height: 100px;
      }
    }
    .page.coordinate {
      height: 1360px;
    }

    .page.right {
      width: 40%;

      .message-login {
        display: none;
      }
    }


    .page.large {
      width: calc( 100vw);
      div {
        h1 {
          font-size: 12px;
        }
      }
    }


  }
  .choose-on-map {
    margin-top:4em;
  }
  .footer {
    height: 100px;
    flex-direction: row;
    .links {
      width: 100px;
      flex-direction: row;
      justify-content: center;

      .links-container {
        flex-direction: row;
        justify-content: center;
        .links {
          width: 200px;
        }
      }
    }
    .container-copyright {
      width: 100%;
    }
  }

  .container-btn-forecast {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    .btn-forecast {
      width :  100px;
      font-size: 12px;
      margin: 1em 1em 1em 1em;
    }
  }
}


.logo-header-site {
  height: 100px;
  width: 190px;
  margin-left: 1em;
}

.logo-header {
  height: 100px;
  width: 100px;
  margin-left: 1em;
}

@media (max-width: 750px) and ( min-height: 400px) {
  .logo-header-site {
    height: 55px;
    width: 120px;
  }
  .logo-header {
    height: 55px;
    width: 55px;
  }
}

.flag-mini {
  height: 10px;
  width: 10px;
}
.container-lang {
  display: flex;
  flex-direction: row;
}

@media (max-width: 750px) and ( min-height: 400px) {
  .container-lang {
    width: 145px;
  }
  .form-inline {
    display: flex;
    flex-direction: row
  }
}

/* graph */
.legend {
  position: absolute;
  width: 112px;
  padding: 3px;
  height: 30px;
  background-color: $black;
  color: $white;
}

#map {
  height: 500px;
}